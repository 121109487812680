import React from 'react';
import Layout from '../../components/common/Layout';
import RightToBeForgottenForm from '../../components/from/RightToBeForgotten/RightToBeForgottenForm';
import SEO from '../../components/SEO';
import Container from '../../components/common/Container';

const RightToBeForgottenFormPage = () => (
  <Layout lang="de">
    <Container>
      <SEO
        title="Recht auf Vergessenwerden - Nextcloud Hosting"
        description="Hier kannst du deine Daten löschen lassen."
      />
      <RightToBeForgottenForm />
    </Container>
  </Layout>
);

export default RightToBeForgottenFormPage;
